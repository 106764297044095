<template>
  <div width="100%" class="viewItemFile">
    <!-- 预览文件 -->
    <div class="image" v-if="fileType == 'image'">
      <div>
        <el-image :src="fileUrl"> </el-image>
      </div>
    </div>
    <div class="docWrap" v-if="fileType == 'doc'">
      <!-- 预览文件的地方（用于渲染） -->
      <div ref="word"></div>
    </div>
    <div v-if="fileType == 'xls'">
      <!-- 用于渲染表格的容器 -->
      <template>
        <el-tabs v-model="tableName" @tab-click="handleClick">
          <el-tab-pane v-for="item in sheetList" :key="item" :label="item" :name="item">

          </el-tab-pane>
        </el-tabs>
        <el-table
            :data="excelData"
            border
            stripe
            :header-cell-style="{ background: '#F5F4F7' }"
        >
          <el-table-column
              type="index"
              label="序号"
              width="60"
              :resizable="false"
              align="center"
          />
          <el-table-column
              v-for="(value, key, index) in excelData[0]"
              :key="index"
              :prop="key"
              :label="key"
          />
        </el-table>
      </template>
<!--      <el-table-->
<!--          :data="excelData"-->
<!--          border-->
<!--          stripe-->
<!--          :header-cell-style="{ background: '#F5F4F7' }"-->
<!--      >-->
<!--        <el-table-column-->
<!--            type="index"-->
<!--            label="序号"-->
<!--            width="60"-->
<!--            :resizable="false"-->
<!--            align="center"-->
<!--        />-->
<!--        <el-table-column-->
<!--            v-for="(value, key, index) in excelData[0]"-->
<!--            :key="index"-->
<!--            :prop="key"-->
<!--            :label="key"-->
<!--        />-->
<!--      </el-table>-->
    </div>
    <iframe
        id="mainIframe"
        v-if="fileType == 'pdf'"
        :src="'/pdfjs-4.2.67-legacy-dist/web/viewer.html?file='+fileUrl"
        type="application/x-google-chrome-pdf"
        width="100%"
        height="600"
    />

<!--    <a id="attach" target="_blank" title="title" :href=''>title</a>-->
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import {serverIp} from "../../../public/config";

export default {
  name: "File_show",
  props:{
    url:{
      type:String,
    },
  },
  data(){
    return{
      fileUrl:"",
      fileType:"",

      // excel
      excelData:[],
      sheetList:[],
      tableName:"",
      baseURL:"",
    }
  },
  mounted() {
    this.baseURL = serverIp.startsWith("localhost") ? `http://${serverIp}` : `https://${serverIp}`
    this.getFile(this.url)
  },
  watch:{
    // 此处监听variable变量，当期有变化时执行
    url(item1,item2){
      getFile(item1)
      // item1为新值，item2为旧值
    }
  },
  methods:{
    getBlob(url) {
      return new Promise(resolve => {
        let xhr = new XMLHttpRequest();
        xhr.open('GET', url, true);
        xhr.responseType = 'blob';
        xhr.onload = () => {
          if (xhr.status === 200) {
            resolve(xhr.response);
          }
        };
        xhr.send();
      });
    },
    getFile(str){
      const backpath = this.baseURL+"/";
      if ( str.toLowerCase().endsWith("png")){
        this.getBlob(backpath+'getImage/'+str).then(res => {
          // this.saveAs(blob, str);
          let blob = new Blob([res], { type:"image/png" });
          this.fileType="image"
          this.fileUrl = URL.createObjectURL(blob);
        });
      } else if ( str.toLowerCase().endsWith("jpg") || str.toLowerCase().endsWith("jfif")){
        this.fileType="image"
        this.getBlob(backpath+'getImage/'+str).then(res => {
          // this.saveAs(blob, str);
          let blob = new Blob([res], { type:"image/jpeg" });
          this.fileUrl = URL.createObjectURL(blob);
        });
      } else if (str.toLowerCase().endsWith("pdf")){
        let timeIframe = setTimeout(this.GetIframeStatus, 10);
        this.fileType="pdf"
        this.getBlob(backpath+'readFile/'+str).then(res => {
          let blob = new Blob([res], { type:"application/pdf" });
          this.fileUrl = URL.createObjectURL(blob);
        });

      } else if (str.toLowerCase().endsWith("xls")||str.toLowerCase().endsWith("xlsx")){
        this.fileType="xls"
        this.getBlob(backpath+'readFile/'+str).then(res => {
          let blob = new Blob([res]);
          this.fileUrl = URL.createObjectURL(blob);
          this.getExcel(this.fileUrl)
        });

      } else if (str.toLowerCase().endsWith("doc")){
        let timeIframe = setTimeout(this.GetIframeStatus, 10);
        this.fileType="pdf"
        this.getBlob(backpath+'readFile/'+str).then(res => {
          let blob = new Blob([res], { type:"application/pdf" });
          this.fileUrl = URL.createObjectURL(blob);
        });

      }


    },
    handleClick(tab, event) {
      // console.log(tab, event);
    },
    getExcel(url){
      this.fileUrl = url;
      this.readWorkbookFromRemoteFile(url);
    },
    // 在线查看excel文件
    readWorkbookFromRemoteFile(url) {
      var xhr = new XMLHttpRequest();
      xhr.open("get", url, true);
      xhr.responseType = "arraybuffer";
      let _this = this;
      xhr.onload = function (e) {
        if (xhr.status === 200) {
          var data = new Uint8Array(xhr.response);
          var workbook = XLSX.read(data, { type: "array" });
          var sheetNames = workbook.SheetNames; // 工作表名称集合
          this.sheetList = sheetNames;
          _this.workbook = workbook;
          this.tableName = sheetNames[0];
          _this.getTable(sheetNames[0]);
        }
      };
      xhr.send();
    },
    getTable(sheetName) {
      var worksheet = this.workbook.Sheets[sheetName];
      this.excelData = XLSX.utils.sheet_to_json(worksheet);
    },
    //加载最大高度
    GetIframeStatus() {
      let iframe = document.getElementById("mainIframe");
      let iframeWindow = iframe.contentWindow;
      //内容是否加载完
      if (iframeWindow.document.readyState == "complete") {
        let iframeWidth, iframeHeight;
        //获取Iframe的内容实际宽度
        iframeWidth = iframeWindow.document.documentElement.scrollWidth;
        //获取Iframe的内容实际高度
        iframeHeight = iframeWindow.document.documentElement.scrollHeight;
        //设置Iframe的宽度
        iframe.width = iframeWidth;
        //设置Iframe的高度
        iframe.height = iframeHeight;
      } else {
        let timeIframe = setTimeout(this.GetIframeStatus, 10);
      }
    },
  },


}
</script>

<style scoped>
</style>