<template>
  <div>
    <el-card>
      <el-form>
        <div v-for="(item,index) in entities">
          <el-form-item :label="item.name" v-if="item.cashier == 0">
            <span v-if="item.valueType === 1 || item.valueType === 2 || item.valueType === 4">{{item.value}}</span>
            <el-date-picker
                v-if="item.valueType === 3"
                v-model="item.value"
                type="datetime"
                :disabled="true"
                placeholder="选择日期时间"/>
            <inventory_show
                :ref="'progressList'+item.id"
                v-if="item.valueType === 5 && item.id !== null && item.id !== undefined"
                :affiliation-id="'cmdb'+item.id"
                :children-ids="item.children"
                :cmdb-id="item.value"/>
            <img
                v-if="item.valueType === 6"
                :src="('http://back.yilanbiaoge.cn/getImage/'+item.value)"
                class="avatar"
                @click="packageUrl=isEmptyString(item.value) ? packageUrl : (item.value),dialogVisible=!isEmptyString(item.value)">
            <el-button v-if="item.valueType === 7" size="small" type="primary" @click="getFile(item.value)" :disabled="isEmptyString(item.value)">下载文件</el-button>
            <el-button v-if="item.valueType === 7" size="small" type="primary" @click="fileShow(item.value)" :disabled="isEmptyString(item.value)">预览文件</el-button>
          </el-form-item>
        </div>
      </el-form>
    </el-card>
    <el-dialog
        fullscreen
        destroy-on-close
        :visible.sync="dialogVisible">
<!--      <img :src="packageUrl">-->
      <file-show ref="fileShow" :url="packageUrl"/>
    </el-dialog>
  </div>
</template>

<script>

import request from "@/utils/request";
import fileShow from "@/views/element/File_show";

export default {

  components:{fileShow},
  name: "cmdb_progress",
  props:{
    affiliationId:{
      type:String,
    },
    cmdbId:{
      type:Number
    },
    childrenIds:{
      type:String
    },
    type:{
      type:String
    },
  },
  data(){
    return{
      properties:[],
      entities:[],
      updateForm:false,
      dialogVisible:false,
      packageUrl:"",
    }
  },
  watch:{
    affiliationId(newVal, oldVal){
      this.selectByCmdb();
    },
    cmdbId(newVal, oldVal){
      this.selectByCmdb();
    },
    childrenIds(newVal, oldVal){
      this.selectByCmdb();
    },
    updateForm(newVal, oldVal){
      this.selectByCmdb();
    },
  },
  mounted() {
    this.selectByCmdb();
  },
  methods:{
    selectByCmdb(){
      request.post("/CMDB/getPropertyChildrensById",this.cmdbId).then(res => {
        this.properties = res.data
      }).then(()=>{
        this.getProgressEntity()
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取cmdb属性失败'
        });
      }
    },
    getEntity(){
      let _ids = (this.childrenIds===null||this.childrenIds===undefined) ? [] : this.childrenIds.split(",").map(Number)
      let entity = (_ids.length === 0) ? this.properties : this.properties.filter(_item=>_ids.indexOf(_item.id) !== -1)
      return entity.map(item =>{
        let _value = item.value
        if (item.valueType === 2){
          _value = Number(_value)
        }
        let _affiliationId = this.affiliationId
        if (this.type === "cashier"){
          _affiliationId = this.affiliationId
        } else if (this.cmdbId !== null && this.cmdbId !== undefined && this.cmdbId !== ""){
          _affiliationId = "cmdb"+_affiliationId
        } else {
          console.log(this.cmdbId,_affiliationId)
        }
        return {
          id:null,
          name:item.name,
          level:item.level,
          cmdbId:item.id,
          value:_value,
          valueType:item.valueType,
          affiliationId:_affiliationId,
          children:item.children,
          cashier:item.cashier,
          cmdbTeam:3,
          mast:item.mast
        }
      })
    },
    getProgressEntity(){
      let entity = this.getEntity()
      request.post("/entity/getInventoryByAffiliationAndCmdbId/"+this.cmdbId+"/"+this.affiliationId).then(res => {
        for (let _data of res.data) {
          let _cmdbId = _data.cmdbId
          let _index = entity.findIndex(_item=>_item.cmdbId===_cmdbId)
          if (_index !== -1){
            let _mast = entity[_index].mast
            let _children = entity[_index].children
            let _cashier = entity[_index].cashier
            entity.splice(_index,1, {..._data,mast:_mast,children:_children,cashier:_cashier})
          }
        }
      }).then(()=>{
        this.entities = entity;
        this.$emit('loadingCompleted',true)
      }), (error) => {
        this.$message({
          type: 'warning',
          message: '获取cmdb属性失败'
        });
      }
    },
    getOption(children){
      if (children){
        return children.split(",")
      } else {
        return []
      }
    },
    // save(){
    //   request.post("/entity/saveEntityList",this.entities).then(res => {
    //     if (res.code === '200'){
    //     }
    //   }), (error) => {
    //     this.$message({
    //       type: 'warning',
    //       message: 'cmdb属性保存失败'
    //     });
    //   }
    // },
    formUpdate(){
      this.updateForm = !this.updateForm
    },
    getFile(str){
      window.open('http://back.yilanbiaoge.cn/getFile/'+str, '_blank')
    },
    fileShow(str){
      this.packageUrl=(str);
      this.dialogVisible=true;
      this.$nextTick(()=>{
        this.$refs.fileShow.getFile(str)
      })
    },
    isEmptyString(str){
      return (str === "" || str === null || str === undefined)
    }
  }
}
</script>


<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>