<template>
  <el-collapse v-model="active" @change="handleChange">
    <div v-if="cashireEntity === undefined || cashireEntity === null || cashireEntity.length === 0">
      <el-empty description="描述文字"></el-empty>
    </div>
    <div
        v-if="cashireEntity !== undefined && cashireEntity !== null && cashireEntity.length !== 0"
        v-for="(item) in cashireEntity">
      <el-collapse-item v-if="item" :name="item.id" :disabled="item.children === null || item.children === undefined || item.children.length ===0">
        <template slot="title">
          <el-descriptions>
            <el-descriptions-item v-if="item.name && (!isChildren || item.valueType == 5)">{{item.name}}</el-descriptions-item>
            <el-descriptions-item v-if="showDate && item.affiliationType !== null && item.affiliationType !== undefined && item.affiliationType !== ''" label="出纳单类型">{{item.affiliationType}}</el-descriptions-item>
            <el-descriptions-item v-if="isChildren && item.valueType != 5" :label="item.name">{{item.value}}</el-descriptions-item>
            <el-descriptions-item v-if="showDate && !isChildren" label="创建时间">{{DateChange(item.createTime)}}</el-descriptions-item>
          </el-descriptions>
        </template>
        <div v-if="item.children !== null && item.children !== undefined && item.children.length !==0">
          <div  style="padding-left: 20px">
            <cashire_entity :cashire-entity="item.children" :is-children="true"/>
          </div>
        </div>
      </el-collapse-item>
    </div>
  </el-collapse>
</template>

<script>
import "@/utils/public.js";
import {DateChange} from "@/utils/public";
export default {
  name: "Cashire_entity",
  props:{
    cashireEntity:{
      type:Array
    },
    isChildren:{
      type:Boolean,
      default: false,
    },
    showDate:{
      type:Boolean,
      default: false,
    }
  },
  data(){
    return {
      active:[]
    }
  },
  methods: {
    handleChange(val) {

    },
    DateChange (time){
      return DateChange(new Date(time),"yyyy-MM-dd EEE HH:mm:ss");
    }
  }
}
</script>

<style scoped>

</style>