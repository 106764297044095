<template>
  <div style="width: 100%;padding: 10px">
    <div style="margin: 10px 0 ;display: flex">
      <div class="block" >
<!--            <span class="demonstration">请选择时间</span>-->
        <div style="margin: 10px 0">
          <div>
            <el-date-picker
                v-model="timeSpase"
                type="datetimerange"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期">
            </el-date-picker>
          </div>
          <el-input v-show="!searchHidden" v-model="search" placeholder="请输入查询名称" style="width:35%"></el-input>
          <el-select
              v-model="tableType"
              @change="getTableDataShow"
              placeholder="请选择查询类型">
            <el-option
                v-for="item in tableTypeList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <el-select
              v-model="typeValue"
              @change="getTableDataShow"
              clearable
              placeholder="请选择出纳单类型">
            <el-option
                v-for="item in typeValueList"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <el-button type="primary" style="margin-left:5px" :loading="formLoading" @click = "select">查询</el-button>
          <el-button v-if="!searchHidden" type="primary" :loading="formLoading" @click="remove">重置</el-button>
          <el-button v-if="typeValue==='仓库出纳单'" :loading="formLoading" type="primary" @click="exportExcel">导出报表</el-button>
        </div>
      </div>
    </div>
    <cashire_entity v-loading="formLoading" :cashire-entity="tableDataShow" :show-date="tableTypeShow" style="width: 80%"/>
    <div style="margin: 10px 0">
      <el-pagination
          :current-page="currentPage4"
          :page-size="size"
          :page-sizes="[5, 10, 20]"
          :total="total"
          layout="total, sizes, prev, pager, next, jumper"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>

<script>

import { exportExcelFromFront } from '@/utils/xlsxExport';
import request from "@/utils/request";
import cashire_entity from "@/views/element/Cashire_entity";
import {DateChange} from "@/utils/public";

export default {

  components: {cashire_entity},
  props:{
    searchHidden: {
      type:Boolean,
      default: false
    },
    name: {
      type:String,
      default: ""
    },
  },
  data() {
    return {
      search: '',
      currentPage4: 1,
      size: 10,
      total: 0,
      tableData: [],
      tableDataStatistic: [],
      tableDataShow: [],
      pickerOptions: {
        shortcuts: [{
          text: '最近一周',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
            picker.$emit('pick', [start, end]);
          }
        }, {
          text: '最近一个月',
          onClick(picker) {
            const end = new Date();
            const start = new Date();
            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
            picker.$emit('pick', [start, end]);
          }
        }]
      },
      typeValue:'',
      typeValueList:["仓库出纳单","工件关联单"],
      tableType:'普通查询',
      tableTypeList:["普通查询","合并查询"],
      tableTypeShow: true,
      timeSpase: '',
      formLoading:false,
    }
  },
  watch:{
    name(item1,item2){
      this.search = item1
      this.select()
    }
  },
  //加载表格
  mounted() {

    const searchParams = new URLSearchParams(window.location.search);
    this.search = this.name

    this.timeSpase = [new Date( this.getZeroTime()) , new Date(this.getZeroTime() + 24 * 60 * 60 * 1000)]
    this.select()
  },
  methods: {
    getZeroTime(){
      return new Date(new Date().toLocaleDateString()).getTime()
    },
    getEndTime(){
      return this.getZeroTime() + 24 * 60 * 60 * 1000 -1
    },
    //整表查询
    select() {
      this.formLoading = true;
      let query = {
        time : this.timeSpase,
        key : this.search
      }
      request.post("/CMDB/entitySelectByCreateBetween",query).then(res => {
        this.tableData = res.data
        this.$nextTick(()=>{
          this.getTableDataShow()
        })
      })
    },
    async getTableDataStatistic(tableData){
      if(tableData === null || tableData.length === 0){
        return []
      }
      let _tableDataStatistic = []
      let i = 0;
      while (i < tableData.length) {
        if (tableData[i].cmdbId === 0){
          tableData.splice(i,1,...tableData[i].children)
          continue
        }
        let _thisChildren = tableData.filter(_item => _item.cmdbId === tableData[i].cmdbId)
        if (_thisChildren === null || _thisChildren.length === 0){
          continue
        }
        let _childrenItem = _thisChildren[0]
        let _childrenGroup = []
        _thisChildren.forEach(_item => {
          if (_item.children !== null && _item.children !== undefined && _item.children.length !== 0){
            _childrenGroup = _childrenGroup.concat(_item.children)
          }
        })
        let _index = _tableDataStatistic.findIndex(_item => _item.cmdbId === tableData[i].cmdbId)
        if (_index === -1){
          _childrenGroup = (_childrenGroup === null || _childrenGroup === undefined || _childrenGroup.length === 0) ? [] : await this.getTableDataStatistic(_childrenGroup)
          _childrenItem.children = _childrenGroup;
          _tableDataStatistic.push(_childrenItem)
        } else {
          _childrenGroup = _tableDataStatistic[_index].children.concat(_childrenGroup)
          _childrenGroup = (_childrenGroup === null || _childrenGroup === undefined || _childrenGroup.length === 0) ? [] : await this.getTableDataStatistic(_childrenGroup)
          _tableDataStatistic[_index].children = _childrenGroup
        }
        i++
      }
      return _tableDataStatistic.filter(item =>
          (item.valueType == 2 || (item.children !== null && item.children !== undefined && item.children.length !== 0))
      );
    },
    //重置
    remove() {
      this.search = ''
      this.select();
    },
    async getTableDataShow(){
      let _tableData = this.tableData.concat()
      if (this.tableType === "合并查询"){
        _tableData = await this.getTableDataStatistic(_tableData)
      }
      if (this.typeValue !== null && this.typeValue !== undefined && this.typeValue !== ""){
        _tableData = _tableData.filter(item => item.affiliationType === this.typeValue)
      }
      let _pageMax = Math.ceil(_tableData.length/this.size)
      this.currentPage4 = this.currentPage4>_pageMax ? _pageMax :this.currentPage4;
      this.currentPage4 = this.currentPage4 < 1 ? 1 : this.currentPage4

      this.tableDataShow = _tableData.slice((this.currentPage4-1)*this.size,this.currentPage4*this.size);

      this.$nextTick(() => {
        this.total = _tableData.length
        this.tableTypeShow = this.tableType === "普通查询"
        this.formLoading = false;
      })
    },
    handleSizeChange(val) {
      this.size = val;
      this.getTableDataShow(this.tableDataShow)
    },
    handleCurrentChange(val) {
      this.currentPage4 = val
      this.getTableDataShow()
    },
    handleEdit(row) {
    },
    DateChange (time){

      return DateChange(new Date(time),"yyyy-MM-dd EEE HH:mm:ss");
    },
    async exportExcel(){
      let caption="出纳记录表"
      let headerList = ['出纳类型','名称','数量','时间','备注','进货单价','售价','成本均价'];
      let cellList = [];
      for (let i = 0; i < this.tableDataShow.length; i++) {
        let _item = this.tableDataShow[i]
        let type = _item.affiliationType
        let time = this.DateChange(_item.createTime)
        let name = _item.name
        let num = 0
        let beizhu = ""
        let danjia = ""
        let shoujia = ""
        let chengben = ""
        for (let j = 0; j < _item.children.length; j++) {
          let _children = _item.children[j]
          if (_children.name === "数量"){
            num = _children.value
          } else if (_children.name.includes("备注")) {
            beizhu = _children.value
          } else if (_children.name === "进货单价") {
            danjia = _children.value
          } else if (_children.name === "售出价") {
            shoujia = _children.value
          } else if (_children.name === "成本均价") {
            chengben = _children.value
          }
        }
        cellList.push([type,name,num,time,beizhu,danjia,shoujia,chengben])
      }
      let exportName='出纳记录表'

      this.$nextTick(()=>{
        let params = {
          caption,
          headerList,
          cellList,
          exportName,
        };
        exportExcelFromFront(params);
      })

    }

  }
}






</script>

<style scoped>

</style>