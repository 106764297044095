<template>
  <div style="width: 100% ;padding: 10px">
    <el-row v-if="!isExcel">
      <el-col style="padding-top: 5px;padding-left: 5px" :span=6>
        <el-switch
            v-model="showPath"
            active-color="#13ce66"
            inactive-color="#ff4949"
            @click="showPath = !showPath">
        </el-switch>
        <span>{{ showPath ? "显示路径" : "不显示路径" }}</span>
        <el-button @click="selectTree">重载路径</el-button>
<!--        <el-button @click="isExcel = true">转换表格</el-button>-->
        <el-card style="height: 75vh;overflow-y: auto" v-loading="treeLoading">
          <el-row>
            <el-col :span="18">
              <el-input
                  placeholder="输入关键字进行过滤"
                  v-model="filterText">
              </el-input>
            </el-col>
            <el-col :span="6">
              <el-button @click="keySelect">筛选</el-button>
            </el-col>
          </el-row>
          <el-tree
              ref="propertyTree"
              :data="propertyTree"
              :props="defaultProps"
              node-key="id"
              :filter-node-method="filterNode"
              :expand-on-click-node="false">
            <span class="custom-tree-node"
                  style="padding: 10px"
                  slot-scope="{ data }">
  <!--            <i class="el-icon-caret-right"/>-->
              <span>
                <el-button
                    type="text"
                    v-if="data.level !== 0 && data.isEntity"
                    @click="show(data)">{{ data.name }}
                </el-button>
                <span
                    v-if="data.level === 0 || !data.isEntity">{{ data.name }}</span>
              </span>
            </span>
          </el-tree>
        </el-card>
<!--        <el-button @click="exportExcel">导出Excel</el-button>-->
      </el-col>
      <el-col style="padding-top: 5px;padding-left: 5px" :span=16>
        <el-row>
          <el-col :span="8">
            <h2 style="padding: 5px 20px 5px 20px">
              {{title}}
            </h2>
          </el-col>
          <el-col :span="8">
            <div style="padding: 5px 20px 5px 20px">
              <el-button
                  v-if="ifShow && (mainId !== null && mainId !== undefined)"
                  @click="inventoryInsert = true">新建出纳单</el-button>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-card v-if="ifShow && mainId" v-loading="formLoading">
            <inventory-show
                ref="inventory"
                v-if="ifShow && (mainId !== null && mainId !== undefined)"
                :affiliation-id="'cashier'+mainId"
                type="cashier"
                @loadingCompleted="formLoading = false"
                :cmdb-id="mainId"/>
          </el-card>
        </el-row>
        <el-row>
          <el-card v-if="ifShow && mainId">
<!--            <iframe-->
<!--                style="border:none"-->
<!--                :width="searchTableWidth"-->
<!--                :height="searchTableHeight"-->
<!--                v-bind:src="reportUrl"-->
<!--            ></iframe>-->
            <Cashier v-if="ifShow && mainId" :name="title" :search-hidden="true"/>
          </el-card>
        </el-row>
      </el-col>
    </el-row>

    <el-row v-if="isExcel">
      <el-cascader
          v-model="cascaderModel"
          :options="propertyTree"
          :props="{ checkStrictly: true }"
          @change="console.log(cascaderModel)"
          clearable></el-cascader>
      <el-button @click="isExcel = false">转换树列</el-button>

    </el-row>
    <el-dialog :visible.sync="inventoryInsert" width="50%" :before-close="handleClose">
      <el-select v-model="outPut" filterable>
        <el-option
            v-for="item in outPutOption"
            :key="item.value"
            :label="item.label"
            :value="item.value">
        </el-option>
      </el-select>
      <cmdb-progress ref="cmdbProgress" :cmdb-id="mainId" affiliation-type="cashier" :out-put="outPut" :update-inventory="true"/>
      <span slot="footer" class="dialog-footer">
        <el-button @click="inventoryInsert = false">取 消</el-button>
        <el-button type="primary" @click="save()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { exportExcelFromFront } from '@/utils/xlsxExport';
import request from "@/utils/request";
import inventoryShow from "@/views/element/inventory_show";
import cmdbProgress from "@/views/element/cmdb_progress";
import Cashier from "@/views/Cashier";
import {getMainMemory} from "@/utils/product";

export default {
  components:{Cashier, inventoryShow,cmdbProgress},
  data() {
    return {
      isExcel:false,
      //表格

      cascaderModel:"",

      //树状
      showPath:true,
      inventoryInsert: false,
      title: "",
      form: {},
      mainId: 0,
      ifShow: false,
      propertyTree: [],
      options:[
        {
          id:1,
          value:"文字"
        },{
          id:2,
          value:"数值"
        },{
          id:3,
          value:"日期"
        },{
          id:4,
          value:"选项卡"
        },{
          id:5,
          value:"列表"
        }
      ],
      defaultProps: {
        children: 'treeChildren',
        label: 'name'
      },
      selectType: "查询本属性",
      groupTypeOption:[{
        value:"SUM",
        name:"求和"
      }],
      groupType:"SUM",
      timeOption:[
        {
          value: '本年',
          label: '本年'
        }, {
          value: '去年',
          label: '去年'
        }, {
          value: '本季度',
          label: '本季度'
        }, {
          value: '上季度',
          label: '上季度'
        }, {
          value: '本月',
          label: '本月'
        }, {
          value: '上个月',
          label: '上个月'
        }, {
          value: '6个月内',
          label: '6个月内'
        }, {
          value: '本周',
          label: '本周'
        }, {
          value: '上周',
          label: '上周'
        }],
      time:"本年",
      treeNumMap:[],
      timeTypeOption:[
        {
          value: 'createTime',
          label: '创建时间'
        },{
          value: 'updateTime',
          label: '修改时间'
        },{
          value: 'intime',
          label: '工件创建时间'
        },{
          value: 'outtime',
          label: '工件入库时间'
        }
      ],
      timeType:"createTime",
      groupDate: {},
      outPutOption: [
        {
          label: "出库单",
          value: true
        },{
          label: "入库单",
          value: false
        }
      ],
      outPut: true,
      reportUrl:"",
      treeLoading:true,
      formLoading:true,
      filterText: "",
    }
  },
  watch: {
    // filterText(val) {
    //   this.$refs.propertyTree.filter(val);
    // }
  },
  //加载表格
  mounted() {

    this.selectTree()
    this.getNumMap()
  },
  methods: {
    //查询cmdb树
    selectTree() {
      this.treeLoading = true
      this.propertyTree = []
      this.title = ""
      this.form = {}
      this.ifShow=false
      if (!this.showPath){
        getMainMemory("entitySelectMemory").then((res) => {
          this.propertyTree = res
          console.log(res)
        }).then(() => {
          this.treeLoading = false
        // request.post("/CMDB/entitySelect").then(res => {
        //   this.propertyTree = res.data
        //   console.log(this.propertyTree)
        // }).then(() => {
        //   this.treeLoading = false
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb树失败'
          });
        }
      } else {
        getMainMemory("getPropertyTreeMemory").then((res) => {
          this.propertyTree = res
          console.log(res)
        }).then(() => {
          this.treeLoading = false
          // request.post("/CMDB/getPropertyTree").then(res => {
          //   this.propertyTree = res.data
          // }).then(() => {
          //   this.treeLoading = false
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb树失败'
          });
        }
      }
    },
    treeNumCorrect(){
      this.propertyTree.forEach(item=>{})
    },
    getNumMap(){
      request.post("/CMDB/getNumMap").then(res => {
        this.treeNumMap = res.data
      })
    },
    getNumById(id){
      let _entity = this.treeNumMap.find(item => item.parentId === id)
      if (_entity !== undefined && _entity !== null){
        return _entity.value;
      } else {
        return 0;
      }
    },
    treeSelect(){
      this.propertyTree.forEach(item => {
        this.propertyTreeSelect(item)
      })
    },
    propertyTreeSelect(item){
      this.$refs.propertyTree.store.nodesMap[item.id].expanded = false;
      if (item.treeChildren !== undefined && item.treeChildren !== null && item.treeChildren.length !== 0){
        item.treeChildren.forEach(children => {
          this.propertyTreeSelect(children)
        })
      }
    },
    selectEntityGroupBy(){
      let selectUrl = ""
      if (this.selectType === "统计子属性"){
        selectUrl = "/entity/getParentEntityGroupBy"
      } else if (this.selectType === "查询本属性"){
        selectUrl = "/entity/getEntityGroupBy"
      }
      let query = {
        cmdbId: this.mainId,
        groupType: this.groupType,
        time:this.time,
        timeType:this.timeType
      }
      request.post(selectUrl,query).then(res => {
        this.groupDate = res.data;
      }), (error) => {

      }
    },
    show(data){
      this.formLoading = this.title !== data.name
      this.title = data.name
      this.form = {}
      this.ifShow = false
      this.ifShow = true
      this.form = data
      this.mainId = data.id
      this.groupDate = {}
      if (this.form.valueType === 5 && this.form.children !== null && this.form.children !== undefined && this.form.children !== "" && (typeof this.form.children) === "string"){
        this.form.children = this.form.children.split(",").map(Number)
      }
      // console.log(data.valueType)
      if (data.valueType===5){
      } else {
        this.entityChildren = []
      }
      this.$nextTick(() => {
        // this.formLoading = false
      })
      // this.selectEntityGroupBy()
    },
    save(){
      this.inventoryInsert = !(this.$refs.cmdbProgress.saveOut())
      setTimeout(() => {
        if (!this.inventoryInsert){
          this.$refs.inventory.formUpdate()
        }
      }, 1000);
    },
    handleClose(done) {
      this.$confirm('确认关闭？')
          .then(_ => {
            done();
          })
          .catch(_ => {});
    },
    //过滤
    filterNode(value, data) {
      if (!value || value === "") return true;
      return data.name.indexOf(value) !== -1;
    },
    keySelect(){
      this.$refs.propertyTree.filter(this.filterText);
      if (this.filterText === undefined || this.filterText === null || this.filterText === ""){
        this.treeSelect()
      }
    },
    async exportExcel(){
      let caption="仓库统计报表"
      let headerList = ['分类','名称','数量'];
      let cellList = [

      ];
      for (let i = 0; i < this.propertyTree.length; i++) {
        let _item = this.propertyTree[i]
        let type = _item.name
        for (let j = 0; j < _item.treeChildren.length; j++) {
          let _children = _item.treeChildren[j]
          let name = _children.name
          let num = 0
          await request.post("/entity/getInventoryByAffiliationAndCmdbId/"+_children.id+"/cashier"+_children.id,this.cmdbId).then(res => {
            res.data.forEach(_data => {
              if (_data.name === "数量"){
                num = _data.value;
              }
            })
          }).then(()=>{
            cellList.push([type,name,num])
          })
        }
      }
      let exportName='仓库统计报表'

      this.$nextTick(()=>{
        let params = {
          caption,
          headerList,
          cellList,
          exportName,
        };
        exportExcelFromFront(params);
      })

    }
  }
}

</script>

<style scoped>
.buleText {
  /*color: #ff0000;*/
}
.orangeText {
  color: #ffcc00;
}
.orangeBlackText {
  border: 1px solid #ffcc00;
}
</style>