<template>
  <el-card :style="width === 0 ? {} : {width:width+'px'}">
    <el-form>
      <div v-for="(item,index) in entities">
        <el-form-item :label="item.name">
          <input v-model="item.value" v-if="item.valueType === 1" :disabled="(item.disable === 1)"/>
          <input type="number" step="0.0001" v-model="item.value" v-if="item.valueType === 2" :disabled="(item.disable === 1)"/>
          <el-date-picker
              v-if="item.valueType === 3"
              v-model="item.value"
              type="datetime"
              :disabled="item.disable === 1"
              placeholder="选择日期时间">
          </el-date-picker>
          <el-select v-model="item.value" v-if="item.valueType === 4" :disabled="item.disable === 1" placeholder="请选择">
            <el-option
                v-for="item in getOption(item.children)"
                :key="item"
                :label="item"
                :value="item">
            </el-option>
          </el-select>
          <cmdb_progress
              :ref="'progressList'+item.id"
              v-if="item.valueType === 5 && item.id !== null && item.id !== undefined"
              :affiliation-id="item.id"
              :children-ids="item.children"
              affiliation-type="cmdb"
              :update-inventory="updateInventory"
              :cmdb-id="item.value"/>
          <span v-if="item.mast && item.valueType !== 5" class="required" style="color:red">*</span>
        </el-form-item>
      </div>
    </el-form>
  </el-card>
</template>

<script>

import request from "@/utils/request";

export default {
  name: "cmdb_progress",

  props:{
    progress:{
      type:String,
      default: "",
    },
    affiliationId:{
      type:Number,
    },
    cmdbId:{
      type:Number
    },
    childrenIds:{
      type:String
    },
    affiliationType:{
      type:String
    },
    outPut:{
      type:Boolean,
      default: true
    },
    updateInventory:{
      type:Boolean,
      default: false
    },
    width:{
      type:Number,
      default: 0
    }
  },
  data(){
    return{
      properties:[],
      entities:[]
    }
  },
  mounted() {
    this.getProgressProperty();
  },
  methods:{
    getProgressProperty(){
      if (this.progress === "" || this.progress === null || this.progress === undefined){
        request.post("/CMDB/getPropertyChildrensById",this.cmdbId).then(res => {
          this.properties = res.data
        }).then(()=>{
          this.getProgressEntity()
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb属性失败'
          });
        }
      }else {
        request.post("/CMDB/getProgressProperty",this.progress).then(res => {
          this.properties = res.data
        }).then(()=>{
          if (this.affiliationId !== 0 && this.affiliationId !== null && this.affiliationId !== undefined){
            this.getProgressEntity()
          }
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb属性失败'
          });
        }
      }
    },
    getEntity(){
      let _ids = (this.childrenIds===null||this.childrenIds===undefined) ? [] : this.childrenIds.split(",").map(Number)
      let entity = (_ids.length === 0) ? this.properties : this.properties.filter(_item=>_ids.indexOf(_item.id) !== -1)
      return entity.map(item =>{
        let _value = item.value
        if (item.valueType === 2){
          // _value = Number(_value)
          _value = 0
        }
        let _affiliationId = this.affiliationType;
        // if (this.progress !== null && this.progress !== undefined && this.progress !== ""){
        //   _affiliationId = "workpiece"
        // } else if (this.cmdbId !== null && this.cmdbId !== undefined && this.cmdbId !== ""){
        //   _affiliationId = "cmdb"
        // } else {
        //   console.log(this.progress,this.cmdbId)
        // }
        if (this.affiliationId !== 0 && this.affiliationId !== null && this.affiliationId !== undefined){
          _affiliationId = _affiliationId + this.affiliationId
        }

        return {
          id:null,
          name:item.name,
          level:item.level,
          cmdbId:item.id,
          value:_value,
          valueType:item.valueType,
          affiliationId:_affiliationId,
          children:item.children,
          cmdbTeam:3,
          mast:item.mast,
          disable:item.disable
        }
      })
    },
    getProgressEntity(){
      let entity = this.getEntity()
      if (this.progress === "" || this.progress === null || this.progress === undefined){
        request.post("/entity/getEntityByAffiliationAndCmdbId/"+this.cmdbId+"/"+"cmdb"+this.affiliationId).then(res => {
          for (let _data of res.data) {
            let _cmdbId = _data.cmdbId
            let _index = entity.findIndex(_item=>_item.cmdbId===_cmdbId)
            if (_index !== -1){
              if (_data.value && (_data.valueType === 2 && !_data.name.includes("价") && !_data.name.includes("金额")) && this.outPut){
                try {
                  _data.value = 0-_data.value;
                } catch (e){}
              }

              let _mast = entity[_index].mast
              let _children = entity[_index].children
              let _disable = entity[_index].disable
              entity.splice(_index,1, {
                ..._data,
                mast:_mast,
                children:_children,
                disable:_disable
              })
            }
          }
        }).then(()=>{
          this.entities = entity;
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb属性失败'
          });
        }
      } else {
        request.post("/entity/getEntityByAffiliationAndProgress/"+this.progress+"/"+"workpiece"+this.affiliationId).then(res => {
          for (let _data of res.data) {
            let _cmdbId = _data.cmdbId
            let _index = entity.findIndex(_item=>_item.cmdbId===_cmdbId)
            if (_index === -1){continue}
            let _mast = entity[_index].mast
            let _children = entity[_index].children
            if (_index !== -1){
              entity.splice(_index,1, {..._data,mast:_mast,children:_children})
            }
          }
        }).then(()=>{
          this.entities = entity;
        }), (error) => {
          this.$message({
            type: 'warning',
            message: '获取cmdb属性失败'
          });
        }
      }
    },
    getOption(children){
      if (children){
        return children.split(",")
      } else {
        return []
      }
    },
    checkMast(){
      function isNotEmpty(value){
        return value!==undefined && value!==null && value!==""
      }
      let list = this.entities.filter(item => item.mast === 1)
      for (let i = 0; i < list.length; i++) {
        if (list[i].valueType === 5){//列表判断刘表中数据是否为空
          if(!this.$refs[`progressList`+list[i].id][0].saveOut()){
            return false
          }
        } else {
          if (!isNotEmpty(list[i].value)){
            return false;
          }
        }
      }
      return true;
    },
    save(){
      if(this.outPut){
        try {
          this.entities.map(item =>{
            if (item.valueType === 2 && !item.name.includes("价") && !item.name.includes("金额")){
              item.value = 0-item.value
              return item;
            } else {
              return item;
            }
          })
        }catch (e){}
      }
      if (this.affiliationId !== 0 && this.affiliationId !== null && this.affiliationId !== undefined){
        request.post("/entity/saveEntityList/"+(this.updateInventory === true ? "true" : "false")+"/"+(this.cmdbId===undefined?0:this.cmdbId),this.entities).then(res => {
          if (res.code === '200'){
          }
        }), (error) => {
          this.$message({
            type: 'warning',
            message: 'cmdb属性保存失败'
          });
        }
      } else {
        request.post("/entity/saveEntityListWithoutAffiliation/"+(this.updateInventory === true ? "true" : "false")+"/"+this.cmdbId,this.entities).then(res => {
          if (res.code === '200'){
          }
        }), (error) => {
          this.$message({
            type: 'warning',
            message: 'cmdb属性保存失败'
          });
        }
      }

    },
    saveOut(){
      if (this.checkMast()){
        this.save();
        return true;
      } else {
        this.$message({
          type: 'error',
          message: '请填写必填项'
        });
        return false
      }
    }
  }
}
</script>

<style scoped>
</style>


