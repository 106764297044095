
export function DateChange (_time,fmt){
    var o = {
        "M+" : _time.getMonth()+1, //月份
        "d+" : _time.getDate(), //日
        "h+" : _time.getHours()%12 == 0 ? 12 : _time.getHours()%12, //小时
        "H+" : _time.getHours(), //小时
        "m+" : _time.getMinutes(), //分
        "s+" : _time.getSeconds(), //秒
        "q+" : Math.floor((_time.getMonth()+3)/3), //季度
        "S" : _time.getMilliseconds() //毫秒
    };
    var week = {
        "0" : "一",
        "1" : "二",
        "2" : "三",
        "3" : "四",
        "4" : "五",
        "5" : "六",
        "6" : "日"
    };
    if(/(y+)/.test(fmt)){
        fmt=fmt.replace(RegExp.$1, (_time.getFullYear()+"").substr(4 - RegExp.$1.length));
    }
    if(/(E+)/.test(fmt)){
        fmt=fmt.replace(RegExp.$1, ((RegExp.$1.length>1) ? (RegExp.$1.length>2 ? "星期" : "周") : "")+week[_time.getDay()+""]);
    }
    for(var k in o){
        if(new RegExp("("+ k +")").test(fmt)){
            fmt = fmt.replace(RegExp.$1, (RegExp.$1.length==1) ? (o[k]) : (("00"+ o[k]).substr((""+ o[k]).length)));
        }
    }
    return fmt;
}
